import './ScrollElement.css'
import {useState} from "react";
function ScrollElement(props) {
    const [hovered, setHovered] = useState(false);

    return (
        <a className='element' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <p className='type' style={{backgroundColor:props.color, borderColor: props.color}}>{props.type}</p>
            <div className='element_content'>
                {hovered && (<h1 className='price'>{props.price}</h1>)}
                {hovered && (<p className='info'>{props.info}</p>)}
            </div>
            <h1 className='heading'>{props.heading}</h1>
        </a>
    )
}

export default ScrollElement;