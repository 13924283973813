import './Header.css'
import logo from '../../images/logo.svg'
import {useState} from "react";
import rus from '../../images/rus.svg'
import eng from '../../images/eng.svg'
import OpenCatalog from "../OpenCatalog/OpenCatalog";
import OpenLang from "../OpenLang/OpenLang";
import TelegramPopUp from "../TelegramPopUp/TelegramPopUp";
import Input from "../Input/Input";
import TelegramLogIn from "../TelegramLogIn/TelegramLogIn";
import OpenCatalogEn from "../OpenCatalog/OpenCatalog";
import InputEn from "../InputEn/Input";
import TelegramPopUpEn from "../TelegramPopUpEn/TelegramPopUp";
import TelegramLogInEn from "../TelegramLogIn/TelegramLogIn";

function HeaderEn() {
    const [ad, setAd] = useState(false);
    const [catalog, setCatalog] = useState(false);
    const [language, setLanguage] = useState(false);
    const [login, setLogin] = useState(false);

    function handleCatalog() {
        return (
            setCatalog(!catalog)
        );
    }

    function handleLanguage() {
        return (
            setLanguage(!language)
        );
    }

    function handleAd() {
        return (
            setAd(!ad)
        );
    }

    function handleLogin() {
        return (
            setLogin(!login)
        );
    }

    return (
        <div className='header'>
            <header>
                <div>
                    <a href=''>
                        <img src={logo}/>
                    </a>
                    {/*<button onClick={handleCatalog} className={catalog ? 'catalog active' : 'catalog'}>*/}
                    {/*    Catalog*/}
                    {/*</button>*/}
                    {/*{catalog && (*/}
                    {/*    <OpenCatalogEn/>*/}
                    {/*)}*/}
                    <div>
                        <InputEn/>
                    </div>
                </div>
                <div>
                    <button onClick={handleLanguage} className={language ? 'language active' : 'language'}>
                        <div><img src={eng}/></div>
                    </button>
                    {language && (
                        <OpenLang/>
                    )}
                    <button onClick={handleAd} className='create_ad'>Create ad</button>
                    {ad && (
                        <TelegramPopUpEn handleAd={handleAd} setLogin={setLogin} login={login}/>
                    )}
                    <button onClick={handleLogin} className='login'>Login via Telegram</button>
                    {login && (
                        <TelegramLogInEn handleLogin={handleLogin} setAd={setAd}/>
                    )}
                </div>
            </header>
        </div>
    )
}

export default HeaderEn;