import './Li.css'

function Li(props) {
    return (
        <div className='li'>
            <img src={props.img}/>
            <a>{props.name}</a>
        </div>
    )
}

export default Li;
