import './TelegramPopUp.css'
import {useEffect} from "react";
import close from '../../images/close.svg'

function TelegramPopUp({ handleAd, setLogin, login}) {

    useEffect(() => {
        setLogin(false);
        // Disable scrolling when the component mounts
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const Login = () => {
        setLogin(!login);
    }
    return (
        <div className='overlay'>
            <div className="popup">
                <button className="close" onClick={handleAd}>
                    <img src={close}/>
                </button>
                <div className="icon">
                    <svg width="36" viewBox="0 0 36 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g id="Canvas" fill="none">
                            <path className="mentalVColor__2KYsO" id="Fill 15" fill-rule="evenodd" clip-rule="evenodd"
                                  d="M 16.4642 1.0587L 0.119349 42.6906C -0.314241 43.7961 0.487163 45 1.65772 45L 34.3435 45C 35.5141 45 36.3141 43.7961 35.8805 42.6906L 19.537 1.0587C 18.9826 -0.3529 17.0187 -0.3529 16.4642 1.0587Z"
                                  transform="translate(36 45) scale(-1)"
                                  fill='white'></path>
                        </g>
                    </svg>
                </div>
                <div className="whoops">Упс!</div>
                <div className="description">Для продолжения Вам<br/>необходимо авторизоваться
                    <br/>через Telegram
                </div>
                <div className="controls">
                    <button className="auth" onClick={Login}>Авторизоваться</button>
                </div>
                <div className="about"><a href="">Подробнее
                    про
                    MentalMarket и авторизацию</a></div>
            </div>
        </div>
    )
}

export default TelegramPopUp;