import './TelegramLogIn.css'
import {useEffect, useRef, useState} from "react";
import close from '../../images/close_black.svg'

function TelegramLogInEn({handleLogin, setAd}) {
    useEffect(() => {
        setAd(false);
        // Disable scrolling when the component mounts
        document.body.style.overflow = 'hidden';

        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const [position, setPosition] = useState('-20')
    const [color1, setColor1] = useState('black')
    const [color2, setColor2] = useState('gray')
    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)

    const First = () => {
        setPosition(-10);
        setColor1('black');
        setColor2('gray');
        setStep2(false);
        setStep1(true);
    }
    const Second = () => {
        setPosition(170);
        setColor2('black');
        setColor1('gray');
        setStep2(true);
        setStep1(false);
    }
    return (
        <div className='overlay_tg'>
            <div className="popup_tg">
                <div className='close'>
                    <img src={close} onClick={handleLogin}/>
                </div>
                <div className='svg_animated'>
                    <svg style={{marginLeft: `${position}px`, transition: '0.5s all'}} width="36" viewBox="0 0 36 45"
                         version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <linearGradient id="mentalGradient" x1="100%" y1="100%">
                            <stop offset="0%" stop-color="#7C10FF"></stop>
                            <stop offset="100%" stop-color="#A95CFF"></stop>
                        </linearGradient>
                        <g id="Canvas" fill="url(#mentalGradient)">
                            <path id="Fill 15" fill-rule="evenodd" clip-rule="evenodd"
                                  d="M 16.4642 1.0587L 0.119349 42.6906C -0.314241 43.7961 0.487163 45 1.65772 45L 34.3435 45C 35.5141 45 36.3141 43.7961 35.8805 42.6906L 19.537 1.0587C 18.9826 -0.3529 17.0187 -0.3529 16.4642 1.0587Z"
                                  transform="translate(36 45) scale(-1)"></path>
                        </g>
                    </svg>
                </div>
                <div className='steps'>
                    <div>
                        <div className='step1' onClick={First} style={{color: color1}}>Step 1</div>
                        <div className='step2' onClick={Second} style={{color: color2}}>Step 2</div>
                    </div>
                    {step1 && (
                        <>
                            <div className='login_content'>
                                Write <purple>/start</purple><br/>
                                to our bot <a href="tg://resolve?domain=MentalMarket_Bot">
                                <purple>@MentalMarket_Bot</purple>
                            </a><br/>
                                and request an authorization code

                            </div>
                            <div onClick={Second}>
                            <button className='written'>Done</button>
                            </div>
                        </>
                    )}
                    {step2 && (
                        <>
                            <div className='login_content'>
                                Enter the code
                            </div>
                            <div className="login_code">
                                <input className="input_code" type="text"
                                                                        placeholder="Bot code" value=""/>
                            </div>
                        </>)}


                </div>
            </div>
        </div>
    )
}

export default TelegramLogInEn;