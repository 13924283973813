import './Home.css'
import Header from "../../Components/Header/Header";
import Li from "../../Components/Li/Li";
import li1 from '../../images/Group 2.png'
import li2 from '../../images/Group 3.png'
import li3 from '../../images/Group 4.png'
import li4 from '../../images/Group 5.png'
import li5 from '../../images/Group 6.png'
import li6 from '../../images/Group 7.png'
import li7 from '../../images/Group 8.png'
import li8 from '../../images/Group 9.png'
import li9 from '../../images/Group 10.png'
import li10 from '../../images/Group 11.png'
import ScrollElement from "../../Components/ScrollElement/ScrollElement";
import Item from "../../Components/Item/Item";
import img1 from '../../images/louis.png'
import img2 from '../../images/igm2.png'
import img3 from '../../images/img3.png'
import img4 from '../../images/img4.png'
import img5 from '../../images/img5.png'
import img6 from '../../images/img6.png'
import img7 from '../../images/img7.png'
import img8 from '../../images/img8.png'
import img9 from '../../images/img9.png'
import img10 from '../../images/img10jpg.jpg'
import HeaderEn from "../../Components/HeaderEn/Header";

function HomeEn() {
    return (
        <div id='home'>
            <HeaderEn/>
            <div className='upper_content'>
                <div className='navigation'>
                    <Li img={li10} name='Activities & hobbies'/>
                    <Li img={li8} name='Business'/>
                    <Li img={li5} name='Clothing'/>
                    <Li img={li4} name='Crypto industry'/>
                    <Li img={li7} name='Electronics'/>
                    <Li img={li6} name='Home & garden'/>
                    <Li img={li3} name='Job'/>
                    <Li img={li9} name='Real estate'/>
                    <Li img={li1} name='Services'/>
                    <Li img={li2} name='Transport'/>
                </div>
                <h1>Weekly top</h1>
            </div>
            <div className="scroll_area">
                <ScrollElement color='#281D6E' heading='Букмекерская кампания' type='Business' price='12 BTC'
                               info='Готовая букмекерская компания с мобильными приложениями ios/android, сайт, домен, постоявшие ставок, страховки на ставки ,живой трафик. 12бтс'/>
                <ScrollElement color='#611AD7' heading='New exchange' type='Services' price='1 BTC'
                               info='1btc💥 just 2 days! New exchange where you can place your token and can buy it for Fiat all over the world and Bank Cards. We were approved by Coin Market Cap. The'/>
                <ScrollElement color='#281D6E' heading='Крипто игра BitKong' type='Business' price='30 BTC'
                               info='Продажа игры на блокчейне с оборотом 800бтс в месяц . Мировая крипто игра ищет владельца. Полностью рабочая игра со всеми настроенными'/>
                <ScrollElement color='#281D6E' heading='Высокоприбыльный гейм бизнес' type='Business' price='1.54 BTC'
                               info='готовая к продвижению новейшая игровая система казино (сайт, домен, экосистема, софт , эквайринг) окупаемость месяц при затрате 20$ в день на трафик'/>
                <ScrollElement color='#60369F' heading='MARINE ANDAMAN CRUISE' type='Transport' price='0.954 BTC'
                               info='Насладитесь отдыхом на люксовой яхте на острове Пхукет.Шикарная яхта для многодневных круизов и вечеринок . Уровень класса люкс, который подходит для селебрити и премиум'/>
                <ScrollElement color='#281D6E' heading='Бизнес по продаже тайской косметики' type='Business'
                               price='12.693 BTC'
                               info='Готовый прибыльный бизнес на продажу или ищу партнёра в 50% долю. Качественная тайская косметика, все для женского здоровья и латекс.'/>
                <ScrollElement color='#1C1C1C' heading='Азиатский трафик' type='Crypto industry' price='10 BTC'
                               info='- Используем более 20 маркетинговых инструментов; - перевод текстов; - подготовка воронок; - база на 20.000.000 крипто азиатов; - мейл'/>
                <ScrollElement color='#611AD7' heading='Услуги Web-разработчика' type='Services' price='0.01 BTC'
                               info='Разработка любых типов сайтов, а также их составляющих: -Дизайн; -Верстка; -Лендинг; -Сайт под ключ; -Консультации; -WordPress; -SEO; Имеется'/>
                <ScrollElement color='#1C1C1C' heading='Услуги для проведения успешного ICO' type='Crypto industry'
                               price='0.95 BTC'
                               info='Оказываем любые услуги по маркетингу проектов, а так же иные,в которых заинтересован проект, в плоть до помощи листинга монеты на биржах, преимущества: рассылки проводим используя'/>
                <ScrollElement color='#281D6E' heading='Рассылка в мессенджерах' type='Business' price='1.347 ETH'
                               info='Выполним под заказ рассылку с оплатой за количество сообщений в Whatsapp/Viber/Direct Instagram 1.Whatsapp от 50к сообщений по 0.8 руб за'/>
                <ScrollElement color='#A585D5' heading='iPhone Xs и iPhone Xs Plus' type='Electronics'
                               price='10.108 ETH'
                               info='Phone Xs с OLED-экраном размером 5,8 дюймов и Большой iPhone Xs Plus с OLED-экраном размером 6,5 дюймов. Могу продать любой айфон с любым размером памяти. Цены и наличие по запросу.'/>
                <ScrollElement color='#B678FF' heading='Кровать из паллетов "Leaba"' type='Home & garden'
                               price='1.749 ETH'
                               info='Продается двухспальная кровать из паллетов, которая идеально подойдет для интерьера в стиле лофт. В основании кровати лежат 4 паллета'/>
                <ScrollElement color='#60369F' heading='AUDI A7' type='Transport' price='21.595 BTC'
                               info='Автомобили от официального дилера Audi в Санкт-Петербурге! Vin -WAUZZZF26KN023523 Комплектация -4KA02Y Двигатель - Бензиновый Тип привода - Полный Объем 3.0 Цвет Интерьера'/>
                <ScrollElement color='#8882CD' heading='Свитшот Культура' type='Clothing' price='0.227 ETH'
                               info='Флагман модельного ряда'/>
            </div>
            <div className='last_added'>
                <h1>Recently added</h1>
            </div>
            <div className='items'>
                <Item color='#8882CD' section='Clothing' img={img1} title='Louis Vuitton x Virgil Abloh'
                      price='0.45 BTC' converter='3540'/>
                <Item color='black' section='Crypto industry' img={img2}
                      title='Жидкость для 1-фазного иммерсионного охлаждения. Иммерсионное масло.' price='0.009 ETH'
                      converter='2'/>
                <Item color='black' section='Crypto industry' img={img3}
                      title='Установки иммерсионного охлаждения для ASIC и GPU.' price='6.046 ETH' converter='1501'/>
                <Item color='black' section='Crypto industry' img={img4} title='Криптообменник под ключ'
                      price='1.968 BTC' converter='15500'/>
                <Item color='black' section='Crypto industry' img={img5} title='Продам Bitmex-аккаунт с 200+ рефералами'
                      price='4.662 BTC' converter='36728'/>
                <Item color='#611AD7' section='Services' img={img6} title='Создам бота Telegram' price='0.02 BTC'
                      converter='155'/>
                <Item color='#00D7C5' section='Job' img={img7} title='Менеджер по продажам(удаленно)'
                      price='0.775 BTC' converter='6103'/>
                <Item color='#611AD7' section='Services' img={img8} title='Отцифрую Ваш бизнес' price='0.604 ETH'
                      converter='150'/>
                <Item color='black' section='Crypto industry' img={img9}
                      title='Необходим терминал для зеркального повторения трейда для биржи Binance' price='0.66 ETH'
                      converter='164'/>
                <Item color='#60369F' section='Transport' img={img10} title='Шины на Хаммер Н2' price='3.059 ETH'
                      converter='760'/>

            </div>
            <div className='sub'>
                <h1 className="sub_title">Stay informed</h1>
                <div className="sub_desc">On our Telegram channel we publish the most interesting ads on a daily
                    basis.<br/>We also make news digest every week about the use of cryptocurrencies all over the
                    world<br/>as well as create ratings of the most unusual lots
                </div>
                <a
                   className="sub_button" target="_blank"><span>Subscribe</span>
                    <svg className="tgLogo__2mLz6" width="18" viewBox="0 0 18 16" version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g className="tgLogoColor__hEFIc tgLogoColor__Bb6uz" id="Подписаться-кнопка"
                               transform="translate(-217.000000, -16.000000)" fill-rule="nonzero">
                                <g id="Group" transform="translate(34.000000, 13.000000)">
                                    <path
                                        d="M183.31794,10.5387 L187.4655,12.0867 L189.07086,17.24952 C189.17358,17.58018 189.57792,17.7024 189.8463,17.48298 L192.15822,15.59826 C192.40056,15.4008 192.74574,15.39096 192.999,15.5748 L197.16888,18.60222 C197.45598,18.8109 197.86272,18.65358 197.93472,18.30666 L200.98938,3.6132 C201.06798,3.23424 200.69562,2.9181 200.33478,3.05766 L183.31308,9.62412 C182.89302,9.78612 182.89668,10.38084 183.31794,10.5387 Z M188.81214,11.26266 L196.91802,6.27024 C197.0637,6.18078 197.21358,6.37776 197.08848,6.4938 L190.39878,12.7122 C190.16364,12.93108 190.01196,13.224 189.969,13.54194 L189.74112,15.2307 C189.71094,15.45618 189.3942,15.47856 189.33198,15.26034 L188.45556,12.18084 C188.35518,11.8296 188.50146,11.45442 188.81214,11.26266 Z"
                                        id="XMLID_497_"></path>
                                </g>
                            </g>
                        </g>
                    </svg>
                </a>
            </div>

            <div className='footer'>
                <div>
                    <div className='footer_content'>
                        <div>
                            <p>© 2018 Mental Market</p>
                            <div>
                                <p>Funded by&nbsp;</p>
                                <a href='https://www.coinbase.com/' className='footer_href'>Coinbase </a>
                                <p>&nbsp;&&nbsp;</p>
                                <a href='https://alertacripto.com/' className='footer_href'>AlertaCripto</a>
                            </div>
                        </div>
                        <div>
                            <a className='footer_href'>Contact us via Telegram
                            </a>
                            <svg className="tgLogo__2mLz6" width="18" viewBox="0 0 18 16" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g className="tgLogoColor__hEFIc tgLogoColor__Bb6uz" id="Подписаться-кнопка"
                                       transform="translate(-217.000000, -16.000000)" fill-rule="nonzero">
                                        <g id="Group" transform="translate(34.000000, 13.000000)">
                                            <path
                                                fill='#8016FF'
                                                d="M183.31794,10.5387 L187.4655,12.0867 L189.07086,17.24952 C189.17358,17.58018 189.57792,17.7024 189.8463,17.48298 L192.15822,15.59826 C192.40056,15.4008 192.74574,15.39096 192.999,15.5748 L197.16888,18.60222 C197.45598,18.8109 197.86272,18.65358 197.93472,18.30666 L200.98938,3.6132 C201.06798,3.23424 200.69562,2.9181 200.33478,3.05766 L183.31308,9.62412 C182.89302,9.78612 182.89668,10.38084 183.31794,10.5387 Z M188.81214,11.26266 L196.91802,6.27024 C197.0637,6.18078 197.21358,6.37776 197.08848,6.4938 L190.39878,12.7122 C190.16364,12.93108 190.01196,13.224 189.969,13.54194 L189.74112,15.2307 C189.71094,15.45618 189.3942,15.47856 189.33198,15.26034 L188.45556,12.18084 C188.35518,11.8296 188.50146,11.45442 188.81214,11.26266 Z"
                                                id="XMLID_497_"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeEn;