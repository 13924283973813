import './Item.css'

function Item(props) {
    return (
        <div className='item'>
            <p className='item_section' style={{backgroundColor: props.color}}>{props.section}</p>
            <div className='item_image'>
                <img src={props.img}/>
            </div>
            <div className='item_content'>
                <p>{props.title}</p>
                <button className="like">
                    <svg className="icon__1zZ4v" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className="heartStyles__1CeeP heartStyles__1XLYP" fill-rule="evenodd" clip-rule="evenodd"
                              d="M0 3.63881C0 1.63396 1.29714 0.00400971 3.27994 0.00400971C4.99811 0.00400971 5.75995 0.858078 6.95084 1.98882C7.15546 2.18427 7.47814 2.18249 7.6806 1.98481C8.91159 0.928248 9.63935 0 11.3515 0C13.3403 0 14.6314 1.62995 14.6314 3.6348C14.6314 7.40594 9.63534 12.8571 7.31372 12.8571C4.99209 12.8571 0 7.40995 0 3.63881Z"
                              transform="translate(1.94116 1.82352)" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"></path>
                    </svg>
                </button>
                <div className='item_price'>
                    <p>{props.price}
                        <gray>&nbsp;≈&nbsp;{props.converter}&nbsp;$</gray>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Item;
