import './App.css';
import Home from "./Pages/Home/Home";
import {BrowserRouter as Router, Link, Route, Routes} from 'react-router-dom';
import HomeEn from "./Pages/HomeEn/Home";
function App() {
  return (
      <Router>
          <Routes>
              <Route path='/' element={<Home/>} />
              <Route path='/en' element={<HomeEn/>} />
          </Routes>
      </Router>
  );
}

export default App;
