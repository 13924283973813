import './Input.css'
import {useEffect, useRef, useState} from "react";

function InputEn(){
    const [isActive, setIsActive] = useState(false);
    const [searchText, setSearchText] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputClick = () => {
        setIsActive(true);
    };

    const handleChange = (event) => {
        setSearchText(event.target.value);
    };
    return(
        <div ref={inputRef}>
            {isActive ? (
                <input
                    type="text"
                    className="input1"
                    placeholder="You are looking"
                    value={searchText}
                    onChange={handleChange}
                />
            ) : (
                <input
                    type="text"
                    className="input2"
                    placeholder="Search"
                    value={searchText}
                    onClick={handleInputClick}
                    readOnly
                />
            )}
        </div>
    )
}

export default InputEn;